import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/css/screen.css"
import ChargeImg from "../../content/assets/illustrations/mobile/destination.svg"
import CatalogImg from "../../content/assets/illustrations/b2bcloud/catalogs.svg"
import OfflineImg from "../../content/assets/illustrations/mobile/offline.svg"
import TimelyImg from "../../content/assets/illustrations/mobile/timely.svg"

const AboutPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle} currentLink="/salesrep">
      <SEO
        title="Trunkroad - AI driven sales insights"
        description="Discover valuable insights about your market, track and verify team
        activity"
        keywords={[
          `B2B`,
          `B2B Commerce`,
          `AI Sales`,
          `Sales Intelligence`,
          "B2B eCommerce",
          "Sales Artificial Intelligence",
          "B2B AI",
          "B2B Sales AI",
        ]}
      />

      <div className="banner">
        <Img
          fluid={data.benchAccounting.childImageSharp.fluid}
          className="banner-img"
        />
        <div className="banner-body">
          <h1 className="centered caption">Super charge your sales force!</h1>
          <h4 className="centered caption">
            Discover valuable insights about your market, track and verify team
            activity
          </h4>
        </div>
      </div>
      <div className="salessections text-center">
        <div className="section sec1">
          <div className="section-txt">
            <h3>Impress clients</h3>
            <div className="sec-img">
              <CatalogImg />
            </div>
            <h4 className="section-text">
              <p>
                Help your sales team have better conversations with an
                easy-to-use digital catalogue, advanced search, and past order
                duplication.
              </p>
            </h4>
          </div>
          <div className="img1">
            <CatalogImg />
          </div>
        </div>
        <div className="section sec2">
          <div className="section-txt">
            <h3>Route Planning and optimization.</h3>
            <div className="sec-img">
              <ChargeImg />
            </div>
            <h4 className="section-text">
              <p>
                With route optimization, sales teams can save more time and be
                resource efficient. With commute predictions based on a
                combination of historical data & real time events reduce
                windshield time, allowing for better field execution.{" "}
              </p>
            </h4>
          </div>
          <div className="img2">
            <ChargeImg />
          </div>
        </div>

        <div className="section sec3">
          <div className="section-txt">
            <h3>Instant-Sync.</h3>
            <div className="sec-img">
              <TimelyImg />
            </div>
            <h4 className="section-text">
              <p>
                Sync customer data and orders automatically from and to the
                cloud for faster confirmation and shipping. No redundant data
                entry anymore.
              </p>
            </h4>
          </div>
          <div className="img3">
            <TimelyImg />
          </div>
        </div>
        <div className="section sec4">
          <div className="section-txt">
            <h3>Offline Access.</h3>
            <div className="sec-img">
              <OfflineImg />
            </div>
            <h4 className="section-text">
              <p>
                See your catalogue and create orders even when internet is
                spotty - out in the field or in a trade show.
              </p>
            </h4>
          </div>
          <div className="img4">
            <OfflineImg />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "HeroSales.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
